.sign-in-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 380px;

    img {
        width: 70%;
        height: auto;
        align-items: center;
    }

    h2 {
        margin: 10px 0;
    }

    .buttons-container {
        display: flex;
        justify-content: space-between;
    }

    .error-message {
        color: darkred;
    }
}