.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;

    .modal-content {
        position: relative;
        background: #fff;
        padding: 20px;
        max-width: 600px;
        width: 90%;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
        max-height: 95vh;
        display: flex;
        flex-direction: column;


        .modal-image-container {
            // flex-shrink: 0; // Prevent the image container from shrinking
            max-height: 55vh;
            overflow: hidden;
            position: relative;
            position: relative;

            img {
                width: 100%;
                height: auto;
                max-height: 70vh;
                object-fit: contain;
            }
        }

        .modal-text,
        .modal-text-overlay {
            flex-grow: 1; // Allow the text container to grow
            overflow-y: auto; // Add scroll if the content is too long
            max-height: calc(95vh - 40px); // Adjust based on the modal-content's max-height and padding
            padding: 20px;
            background: #f9f9f9; // Slightly off-white background for the bio text
            color: #333; // Darker text color for readability

            h2 {
                margin-top: 0;
            }
        }

        .modal-text-overlay {
            position: absolute;
            bottom: 0; // Align with the bottom of the image
            width: 100%; // Same width as the image container
            background: rgba(0, 0, 0, 0.5); // Semi-transparent background
            color: #fff; // Light text color for contrast
            padding: 20px; // Padding inside the overlay
            box-sizing: border-box; // Include padding in height calculation
            max-height: 50%; // Maximum height it can take up on the image
            overflow-y: auto; // Allow scrolling within the overlay
            text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8); // Text shadow for readability

            // Ensure the text starts from the top of the overlay
            display: flex;
            flex-direction: column;
            justify-content: start;
        }
    }

    .close {
        position: absolute;
        top: 10px; // Adjust as necessary
        right: 10px; // Adjust as necessary
        z-index: 1100; // Ensure it's above other elements
        cursor: pointer;
        font-size: 30px; // Adjust as necessary
        color: #fff;
        padding: 10px; // Increase padding to make the button easier to tap
        border-radius: 50%;

        cursor: pointer;

        &:hover {
            color: #f1f1f1;
        }
    }
}