.mentor-card-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; // Space between items, pushing the button to the bottom
    position: relative;
    height: 300px;

    .image-container {
        position: relative;
        flex: 8;



        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            background-position: center center;
            border: 1px solid #e0e0e0; // Soft border color
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
            border-radius: 4px;

            display: block; // This removes the extra space below the image
        }

        .signup-button {
            position: absolute;
            bottom: 50px;
            /* Adjust this value to move the button slightly above the bottom edge of the image */
            left: 50%;
            transform: translateX(-50%);
            /* ... other styles ... */
            // opacity: 0.7;
            // display: flex;
            // justify-content: center;
            // align-items: center;
        }
    }

    .image-container .registered-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.6);
        border: 1px solid #e0e0e0; // Soft border color
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        /* Semi-transparent overlay */
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        pointer-events: none;
        /* Adjust as needed */
    }

    &:hover {
        img {
            opacity: 0.8;
        }

        button {
            opacity: 0.85;
            display: flex;
        }
    }

    .bio-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        /* High z-index to ensure it's above other elements */
    }

    .bio-content {
        background: white;
        padding: 20px;
        border-radius: 5px;
        width: 50%;
        z-index: 1000;

        /* Other styling */
    }

    .footer {
        flex: 2;
        flex-direction: column;
        display: flex;
        font-size: 18px;

        .row {
            display: flex;
            width: 100%;
        }

        .name {
            width: 80%;
            margin-bottom: 1px;
        }

        .name a {
            position: relative;
            color: #333333;
            /* Custom color */
            text-decoration: none;
            cursor: pointer;
            /* Ensures cursor changes to pointer */
            transition: color 0.3s ease-in-out;
            /* Smooth color transition */
        }

        .name a:hover {
            color: #555555;
            /* Color change on hover */
        }

        .name a::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #ff8800;
            /* Custom color */
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
            /* Smooth transform transition */
        }

        .name a:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }


        .slots {
            width: 20%;
            text-align: right;
        }

        .full-width {
            width: 100%;
        }

    }
}