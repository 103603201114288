.unauthorized-container {
    padding: 20px 40px;
    max-width: 1200px;
    margin: auto;

    .email {
        color: #2a7fba;
        font-weight: bold;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}