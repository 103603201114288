body {
  margin: 0;
  padding: 2rem;
  font-size: 1rem;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5rem;

  -moz-osx-font-smoothing: grayscale;
}

// html {
//   font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
//   font-size: 1.3em;
//   padding: 2rem;
//   line-height: 1.5rem;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}