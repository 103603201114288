.sign-up-container {
    display: flex;
    flex-direction: column;
    width: 380px;

    h2 {
        margin: 10px 0;
    }

    .error-message {
        color: darkred;
    }
}