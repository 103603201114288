.dashboard {
    padding: 20px 40px;
    max-width: 1200px;
    margin: auto;
    border-radius: 8px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .dashboard-title {
        text-align: center;
        color: #333;
    }

    .statistics-section,
    .signups-section {
        margin: 20px 0;
        background-color: #fff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);

        h2 {
            color: #555;
            margin-bottom: 15px;
        }

        p {
            color: #666;
            margin: 5px 0;
        }
    }

    .download-button {
        padding: 10px 15px;
        background-color: #4a90e2;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
            background-color: #3a7bd5;
        }
    }
}