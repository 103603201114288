.mentors-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* auto-fit works with minmax for responsive cards */
    gap: 20px;
    /* Adjusted for both row-gap and column-gap */
    padding: 20px;
    /* Added padding for whitespace around the grid */
    max-width: 1200px;
    /* Max width to ensure whitespace on sides */
    margin: auto;
    /* Centers the container */

    .title {
        font-size: 28px;
        margin-bottom: 25px;
        cursor: pointer;
    }
}

/* Media Queries for responsive grid columns */
@media (max-width: 1200px) {
    .mentors-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

@media (max-width: 768px) {
    .mentors-container {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
}