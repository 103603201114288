.navigation {
  height: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 8px -9px;

  .logo-container {
    height: 100%;
    width: 70px;
    margin-right: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-image {
    width: 100%;
    /* Make the image fill the container */
    height: 100%;
    /* Make the image fill the container */
    object-fit: contain;
    /* Preserve aspect ratio without cropping */
  }

  .right-nav-links {
    display: flex;
    align-items: center;
  }

  .right-nav-links span {
    padding: 10px;
    padding-bottom: 20px;
    /* Adjust as needed */
    border-bottom: 4px solid transparent;

  }

  .left-nav-links {
    font-family: 'Open Sans';
    font-weight: bold;

    /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: flex-start;

  }

  .nav-links-container {
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 0 1em;
    display: flex;
    justify-content: space-between;

    .nav-link {
      position: relative; // Set relative positioning for the nav-link
      display: flex;
      align-items: center; // Ensures vertical centering of the text
      height: 100%; // Match the height of the navigation bar
      /* ... other nav-link styles ... */

      &.active::after {
        content: ''; // Necessary for the pseudo-element to work
        position: absolute; // Position absolutely within the nav-link
        bottom: -4px; // Position the border below the nav-link
        left: 0;
        width: 100%; // Span the entire width of the nav-link
        border-bottom: 4px solid orange !important; // Your active border
      }
    }


    .nav-link.home {
      padding-right: 1em;
      padding-left: 1em;
      /* Adjust the value as needed */
    }

    /* Or, add padding to the left of the "FAQ" link */
    .nav-link.faq {
      padding-left: 1em;
      padding-right: 1em;

      /* Adjust the value as needed */
    }


    .nav-link:hover {
      color: #007bff;
      cursor: pointer;
      /* Or any other color that fits your design */
    }

    @media (max-width: 768px) {

      /* Adjust breakpoint as needed */
      .left-nav-links {
        padding-left: 20px;
        /* Smaller padding on smaller screens */
      }

      .navigation {
        flex-direction: column;
        align-items: center;
      }

      .nav-links-container {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}