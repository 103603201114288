.mentees-list {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;

    .title {
        text-align: center;
        color: #333;
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        .mentee-item {
            background-color: #fff;
            padding: 10px 15px;
            margin-bottom: 10px;
            border-radius: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

            .name {
                font-weight: bold;
            }

            .email {
                color: #555;
                font-style: italic;
            }
        }
    }
}