.mentorship-faq {
    padding: 20px 40px;
    max-width: 1200px;
    margin: auto;
    color: #333;

    h1 {
        text-align: center;
        color: #4A4A4A;
    }

    .faq-content {
        margin: 20px;

        .question {
            font-weight: bold;
            margin-top: 20px;
        }

        p {
            margin-left: 20px;
        }

        .table-container {
            max-width: 800px;
            margin: auto;
            font-family: 'Arial', sans-serif;
            background-color: #f8f8f8;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            text-align: left;
        }

        th {
            background-color: #4a4a4a;
            color: #fff;
            font-size: 16px;
            padding: 12px 15px;
        }

        td {
            padding: 12px 15px;
            border-bottom: 1px solid #ddd;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #e8e8e8;
        }

        .email {
            color: #2a7fba;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }


}